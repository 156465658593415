import {  requestFile } from '../globalAxios';
import {serviceApi } from '../url';

  const createOrganisation = async (data) => {
    const formData = new FormData();
    formData.append('name', data.organisation.orgname);
    formData.append('description', data.organisation.orgdescription);
    formData.append('facebookLink', data.organisation.facebooklink);
    formData.append('linkedinLink', data.organisation.linkedinlink);
    formData.append('websiteLink', data.organisation.websitelink);
    formData.append('logo', data.organisation.logo);

    return await requestFile('post', serviceApi.CREATE_ORGANISATION,formData);
  };
export {createOrganisation};