import { Link } from "react-router-dom";
import MemberManagementService from "../../domain/usecase/member_management_service";
import ImageLogo from "../upload_log";
import { InputAdornment, TextField } from "@mui/material";


const Signup= ()=>{

    const {signin,setMemberData,isLicenceCheck,toogleLicencecheck,toogleActiveSubscription,loading,memberData,errorMemberData,isErrorFound,formValidation,initErrorMemberData} = MemberManagementService();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default behavior
        window.open("/licence", "_blank"); // Open the link in a new tab
      };

    return(
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-from-wrap">
                        <div className="section-heading">
                        <div className="sub-title">Create you account and injoy our services</div>
                           <h2 className="section-title">Subscription</h2>                            
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-form">
                                    <p>
                                    <label>Enter member details </label>
                                    
                                    <TextField id="outlined" error={errorMemberData.name.activate} helperText={errorMemberData.name.message} onChange={(e)=>{                                            
                                            setMemberData("name",e.target.value); 
                                        }} 
                                        value={memberData.name} label="name" placeholder="name" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    
                                    <TextField id="outlined" error={errorMemberData.subemail.activate} helperText={errorMemberData.subemail.message} onChange={(e)=>{                                            
                                            setMemberData("subemail",e.target.value); 
                                        }} 
                                        value={memberData.subemail} label="subemail" placeholder="e-mail" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" error={errorMemberData.whatsappnumber.activate} helperText={errorMemberData.whatsappnumber.message} type="number" onChange={(e)=>{                                            
                                            setMemberData("whatsappnumber",e.target.value); 
                                        }} 
                                        value={memberData.whatsappnumber} label="whatsappnumber" placeholder="Whatsapp Number" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" error={errorMemberData.phonenumber.activate} helperText={errorMemberData.phonenumber.message} type="number" onChange={(e)=>{                                            
                                            setMemberData("phonenumber",e.target.value); 
                                        }} 
                                        value={memberData.phonenumber} label="Phone number" placeholder="Phone Number" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" error={errorMemberData.password.activate} helperText={errorMemberData.password.message} onChange={(e)=>{                                            
                                            setMemberData("password",e.target.value); 
                                        }} 
                                        value={memberData.password} label="Password" placeholder="password" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" error={errorMemberData.confpassword.activate} helperText={errorMemberData.confpassword.message} onChange={(e)=>{                                            
                                            setMemberData("confpassword",e.target.value);  
                                        }} 
                                        value={memberData.confpassword} label="Confirm Password" placeholder="Confirm Password" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="contact-form">
                                <p>
                                <label>Enter organisation details </label>
                                    <TextField id="outlined" error={errorMemberData.organisation.orgname.activate} helperText={errorMemberData.organisation.orgname.message} onChange={(e)=>{                                            
                                            setMemberData("orgname",e.target.value); 
                                        }} 
                                        value={memberData.organisation.orgname} label="Organisation Name" placeholder="Organisation Name" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p> 
                                    <TextField id="outlined" error={errorMemberData.organisation.orgdescription.activate} helperText={errorMemberData.organisation.orgdescription.message} onChange={(e)=>{                                            
                                            setMemberData("orgdescription",e.target.value); 
                                        }} 
                                        value={memberData.organisation.orgdescription} label="Organisation description" placeholder="Organisation Description" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p> 
                                    <p>  
                                    <TextField id="outlined" onChange={(e)=>{                                            
                                            setMemberData("facebooklink",e.target.value); 
                                        }} 
                                        value={memberData.facebooklink} label="Facebook Link" placeholder="Facebook Link" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" onChange={(e)=>{                                            
                                            setMemberData("linkedinlink",e.target.value); 
                                        }} 
                                        value={memberData.linkedinlink} label="Linkedin Link" placeholder="Linkedin Link" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <TextField id="outlined" onChange={(e)=>{                                            
                                            setMemberData("websitelink",e.target.value); 
                                        }} 
                                        value={memberData.websitelink} label="Website Link" placeholder="Website Link" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                    </p>
                                    <p>
                                    <ImageLogo title={"Logo"} imageKey="Organisationlogo" />
                                    </p>
                            </div>
                            
                            </div>
                            <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-start'>
                                        <p> 
                                            <label>
                                                <input type="checkbox" name="licence" checked={isLicenceCheck} onChange={toogleLicencecheck} /> 
                                                &nbsp;By subscribing you agry with all our <Link to="/licence" onClick={handleClick} >Conditions and rules</Link>.
                                            </label>
                                        </p>
                                        </div>
                                        
                                    </div>

                                    {loading?<button className="button-round col-12 mt-2" >Loading ...</button>:<button onClick={()=>{
                                        initErrorMemberData();
                                        formValidation();
                                        setMemberData("rolename","Admin"); 
                                    //call subscription service
                                    if(isLicenceCheck){
                                        console.log(isErrorFound)
                                        if(!isErrorFound){
                                            signin(); 
                                        }
                                            
                                    }
                                        
                                    }}  className="button-round col-12 mt-2">Signup</button>
                                    }
                                    <div className='col d-flex justify-content-center mt-2'>
                                    <label>
                                            &nbsp;You have an account?  <Link to="#" onClick={toogleActiveSubscription} >Login</Link>.
                                        </label>
                                    </div>
                        </div>
                    </div>
                </div>
                </div>
        </>
    )
}

export default Signup;