import React, { useState } from 'react';
import Spinner from '../spinner';
import ModalPayementService from '../../domain/usecase/modal_payement_service';
import { Link } from 'react-router-dom';
import FormPayementBankCard from './form_payemrnt_bank_card';
import FormPayementMtnMM from './form_payement_mtn_mm';
import FormPayementOrangeMM from './form_payement_orange_mm';

const ModalPayement = () => {
  //const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  
  //const handleShow = () => setShow(true);

  const { show, loading,toogleShow,toogleLoading,step1,step2,isCheckPayment,isMtnPayement,isOrangePayement,toogleIsCheckPayment,
    toogleIsMtnPayement,toogleIsOrangePayement
   } = ModalPayementService();
  
    return (
        <>
          <button className="button-round" onClick={toogleShow} >
            Place Order
          </button>
    
          <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {step1&&<h5 className="modal-title">Purshare your ticket</h5>}
                  {step2&&<h5 className="modal-title">Billing information</h5>}
                  <button type="button" className="close" aria-label="Close" onClick={toogleShow}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                    <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="cart-section">
                    <div className="container">
                        <div className="cart-list-inner">
                            <form action="#">
                                <div className='row '>
                                    {step1&&<div className='col col-md-8'>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Sub Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td data-column="Product Name">VIP</td>
                                                    <td data-column="Price">XAF 10000</td>
                                                    <td data-column="Quantity" className="count-input">
                                                        <div>
                                                        <a className="minus-btn" href="#"><i className="fa fa-minus"></i></a>
                                                        <input className="quantity" type="text" value="0"/>
                                                        <a className="plus-btn" href="#"><i className="fa fa-plus"></i></a>
                                                        </div>
                                                    </td>
                                                    <td data-column="Sub Total">XAF 0</td>
                                                </tr>
                                                <tr>
                                                    <td data-column="Product Name">MEDIUM</td>
                                                    <td data-column="Price">XAF 5000</td>
                                                    <td data-column="Quantity" className="count-input">
                                                        <div>
                                                            <a className="minus-btn" href="#"><i className="fa fa-minus"></i></a>
                                                            <input className="quantity" type="text" value="0"/>
                                                            <a className="plus-btn" href="#"><i className="fa fa-plus"></i></a>
                                                        </div>
                                                    </td>
                                                    <td data-column="Sub Total">XAF 0</td>
                                                </tr>
                                                <tr>
                                                    <td data-column="Product Name">STANDART</td>
                                                    <td data-column="Price">XAF 2000</td>
                                                    <td data-column="Quantity" className="count-input">
                                                        <div>
                                                            <a className="minus-btn" href="#"><i className="fa fa-minus"></i></a>
                                                            <input className="quantity" type="text" value="0"/>
                                                            <a className="plus-btn" href="#"><i className="fa fa-plus"></i></a>
                                                        </div>
                                                    </td>
                                                    <td data-column="Sub Total">XAF 0</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    {step2&&<div className='col col-md-8'>
                                        <div className='row'>
                                            <div className='col col-md-6 text-left'>
                                                <span><Link to="">Login</Link> for a faster experience.</span>
                                            </div>    
                                            <div className='col col-md-6 text-align-rigth'>
                                                <span>*</span>Required
                                            </div>
                                        </div>    
                                        <form>
                                            <div className='row mt-2'>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                    <label>First Name *</label>
                                                    <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>last Name *</label>
                                                        <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                    <label>Email *</label>
                                                    <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Confirm Email *</label>
                                                        <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <span className="byline">
                                                    <h4 >Select your payment method </h4>
                                                </span>
                                            </div>
                                            <div className="payment-wrap">
                                                <div className="form-group">
                                                    <div className="custom-radio-field" >
                                                        <div className='row' >
                                                            <label className='payement_option' onClick={toogleIsCheckPayment}>
                                                                <input type="radio" name="s" value="2"  />
                                                                <span className="radio-field"></span>
                                                                Check payment  <img src="/assets/images/site-logo1.png" style={{width:"40px"}}/>
                                                            </label>    
                                                                                                               
                                                        </div>
                                                    </div>
                                                    {isCheckPayment && <FormPayementBankCard /> }    
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-radio-field" onClick={toogleIsMtnPayement} >
                                                        <label>
                                                            <input type="radio" name="s" value="3" />
                                                            <span className="radio-field"></span>
                                                            MTN Mobile Money <img src="/assets/images/site-logo1.png" style={{width:"40px"}} alt="" />
                                                        </label>
                                                         
                                                    </div>
                                                    {isMtnPayement && <FormPayementMtnMM />}
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-radio-field"  onClick={toogleIsOrangePayement}>
                                                        <label>
                                                            <input type="radio" name="s" value="4" />
                                                            <span className="radio-field"></span>
                                                            Orange Mobile Money  <img src="/assets/images/site-logo1.png" style={{width:"40px"}} alt="" />                                           
                                                        </label>                                                    
                                                    </div>
                                                    {isOrangePayement && <FormPayementOrangeMM />}
                                                </div>
                                            </div>
                                            
                                        </form>
                                    </div>}
                                    <div className='col col-md-4'>
                                        <div className="updateArea">
                                            <div className="input-group-wrap">
                                                <input type="text" className="form-control" placeholder="I have a discount coupon"/>
                                                <a href="#" className="button-round mt-2">apply coupon</a>
                                            </div>
                                        
                                        </div>
                                        <div className="totalAmountArea">
                                            <ul className="list-unstyled">
                                                <li><strong>Sub Total</strong> <span>$ 792.00</span></li>
                                                <li><strong>Vat</strong> <span>$ 18.00</span></li>
                                                <li><strong>Grand Total</strong> <span className="grandTotal">$ 810.00</span></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="text-center">
                                            {loading?<div className="button-round"> <Spinner/> Loading...</div>:<div className="button-round" onClick={toogleLoading}> Purshare</div>}
                                            
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </main>
                
                </div>
                
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show"></div>}
        </>
      );
  
};

export default ModalPayement;
