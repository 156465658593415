import toast from 'react-hot-toast';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { creatEventPricing } from '../../data/api/event_pricing_api';

const PricingStore = create(  persist((set, get) => ({
      show: false,
      showUpdate: false,      
      pricings: [],
      pricingItem: { id: 0, category: "", amount: 0, numberPlace: 0, options: "" },
      loading: false,
      errorPrincing: "",
      isErrorPricing: false,
      initData:()=>{
        set({pricingItem:{id:0,category: "", amount: 0, numberPlace: 0, options: ""}});
      },
      initDataUpdate:(keyPlace)=>{
        const updateItem = get().pricings.filter((item,k)=>{
          console.log("item.id -- ",item.category);
          return item.category === keyPlace
        });
        if(updateItem!==null)
        set({pricingItem:updateItem[0] });
      },
      toggleShow: () => {
        console.log("loader");
        get().initData();
        set({ show: !get().show,  });
      },
      toggleShowUpdate: (keyPlace,isInitData) => {
        if(isInitData)
          get().initDataUpdate(keyPlace);
        set({showUpdate:!get().showUpdate});
      },
      resetErrorPricing:(check,message)=>{
        set({errorPrincing:message,isErrorPricing:check});
      },
      getPricingData:(id)=>{

      },
      addPricing: () => {
        const newlist = get().pricings.filter(item => item.category === get().pricingItem.category);
        if(newlist.length===0){
          const newPricings = [...get().pricings, get().pricingItem];
          set({ pricings: newPricings });
        }else{
          toast.error("This ticket already exist.");
        }
        
      },
      updatePricing:(id)=>{
        var index= -1;
        const updateItem = get().pricings.filter((item,k)=>{
          if(item.id === id){
            index = k;
          }
          return item.id === id
        });  
        
        if(updateItem.length>0&&index>=0){
          console.log(get().pricingItem);
          var listPricing = get().pricings;
          listPricing[index] = get().pricingItem;
          
          set({pricings:listPricing});
        }
        set({showUpdate:!get().showUpdate });
      
      },
      savePricing:async ()=>{

        for (const element of get().pricings.array) {
          const response = await creatEventPricing(element);
          console.log(response);
          // Handle response if needed
        }
        
      },
      deleteItem: (cat) => {
        const newlist = get().pricings.filter((item,k) => item.id !== cat);
        set({ pricings: newlist });
      },

      setPricingItem: (key, value) => {
        const newPricingItem = { ...get().pricingItem, [key]: value };
        set({ pricingItem: newPricingItem });
        console.log(`${key}: ${value}`);
      }
    }),
    {
      name: 'create_pricings-storage', 
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default PricingStore;
