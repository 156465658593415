import React from 'react';
import MemberManagementService from '../../domain/usecase/member_management_service';
import Loader from '../loader';
import { Link } from 'react-router-dom';
import Signup from './signup';
import Login from './login';

const ModalSubscription = ({show,isLogin,isSubscription,toogleShow}) => {
   const {isSubscriptionActivate} = MemberManagementService();

   const handleClick = (e) => {
      e.preventDefault(); // Prevent the default behavior
      window.open("/licence", "_blank"); // Open the link in a new tab
    };
  
    return (
        <>  
          <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title"></h5>
                  
                  <button type="button" className="close" aria-label="Close" onClick={toogleShow}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="contact-page-section">
               
                        <div className="contact-form-inner">
                           <div className="container">
                              <div className="contact-from-container">
                                 {isSubscriptionActivate?<Signup />:<Login />}
                              </div>
                           </div>
                        </div>
                     </div>
               </main>
                
                </div>
                
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show"></div>}
        </>
      );
  
};

export default ModalSubscription;
