
import {create} from 'zustand';
import { getTownAndCountry } from '../data/api/util_api';

// const latitude = '3.8743742';
// const longitude = '11.4931874';

const utilStore = create((set,get) => ({
  town: "",
  country: "",
  loading: false,
  error: null,
  getTownAndCountry: async (latitude, longitude) => {
    set({ loading: true, error: null });
    try {
      const response = await getTownAndCountry(latitude, longitude);
      const data = await response.json();
      
      //const town = data.address.city || data.address.town || data.address.village || '';
      const town = data.address.town || '';
      const country = data.address.country || '';
      set({town,country, loading: false });    
    } catch (error) {
      console.error('Error:', error);
    }
  },
}));


const convertDateFormat = (dateString) => {
    // Parse the input date string into a Date object
  const dateParts = dateString.split('-');
  const year = dateParts[0];
  const month = dateParts[1] - 1; // Months are 0-based in JS Date
  const day = dateParts[2];
  const date = new Date(year, month, day);

  // Format the date to 'Month day, year'
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
  };

  const convertDate = (datetime)=>{
    var date = datetime!==""?new Date(datetime):new Date();
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    var year = date.getFullYear();
    var formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  const convertTime = (timestamp)=>{
    const date = new Date(timestamp);

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const timeString = `${hours}:${minutes}:${seconds}`;
    return timeString;
  }


export {utilStore,convertDateFormat,convertDate,convertTime};


