import { request, requestFile } from '../globalAxios';
import {serviceApi } from '../url';

  const login = async (data) => {
  console.log(data);
    const jsonData = {
      "email": data.email,
      "username": data.email,
      "password": data.password
    };

    const response =  await request('post', serviceApi.LOGIN_MEMBER,jsonData);
    console.log("--- login success init---");
      console.log(response);
    return response;
  };
  const signup = async (data,ordId=null) => {
    const jsonData = {
      "name": data.name,
      "email": data.subemail,
      "whatsappNumber": data.whatsappnumber,
      "phoneNumber": data.phonenumber,
      "password": data.password,
      "roleId": 1,
      "organisationId": ordId
    };

    return await request('post', serviceApi.SIGNUP_MEMBER,jsonData);
  };

  const checkToken = async (token,userName) => {
    const jsonData = {
      "token": token,
      "userName": userName,
    };

    return await request('post', serviceApi.CHECK_TOKEN,jsonData);
  };
export {login,signup,checkToken};