
import { request } from "../globalAxios";
import { serviceApi } from "../url";


const getTownAndCountry = async (latitude, longitude) => {
    const response =  await request('get', serviceApi.GET_ALL_EVENT+"lat="+{latitude}+"&lon="+{longitude});
    return response;
}

export {getTownAndCountry};