import PricingStore from "../domain/usecase/model_pricing_service";

const PricingDetails = ({place,amount,desc,keyPlace})=>{

    const {deleteItem  } = PricingStore();

    return(
        <>
            <li >
                <div className="row" >
                    
                    <div className="col">
                        <h5>
                            {keyPlace} - {amount} XAF 
                        </h5>
                        <span className="byline">
                            {place>0?place+" Places remind":"SOLD OUT"} <br></br>
                        </span>
                            {place>0&&<span className="byline">
                            {desc}
                        </span>}
                    </div>
                    
                    
                    
                </div>                
            </li>
        </>
    );
}

export default PricingDetails;

