import {serviceApi } from '../url';
import RequestResponse from '../../domain/model/request_responce';
import EventModel from '../../domain/model/event_model';
import { request, requestFile } from '../globalAxios';

const getEvents = async (page,pageSize) => {
  const response =  await request('get', serviceApi.GET_ALL_EVENT,{params:{
    page:page,size:pageSize
  }},);
  return new RequestResponse(response.data.map((event)=>new EventModel(event)),response.sucess,response.pageSize,response.page,response.exception);
};


const saveEvents = async (eventDTO,key) => {
  const formData = new FormData();
  // Append the non-file fields
  formData.append('title', eventDTO.title);
  formData.append('organizer', eventDTO.organizer);
  formData.append('latitude', eventDTO.latitude);
  formData.append('longitude', eventDTO.longitude);
  formData.append('area', eventDTO.area);
  formData.append('tag', eventDTO.tag);
  formData.append('summary', eventDTO.summary);
  formData.append('description', eventDTO.description);
  formData.append('offline', eventDTO.offline);
  formData.append('country', eventDTO.country);
  formData.append('startHour', eventDTO.startHour);
  formData.append('endHour', eventDTO.endHour);
  formData.append('town', eventDTO.town);
  formData.append('quantity', eventDTO.quantity);
  formData.append('periodique', eventDTO.periodique);
  formData.append('singleEvent', eventDTO.singleEvent);
  formData.append('recurcingEvent', eventDTO.recurcingEvent);
  formData.append('displayStartTime', eventDTO.displayStartTime);
  formData.append('displayEndTime', eventDTO.displayEndTime);
  formData.append('activate', true);
  formData.append('firstTime', eventDTO.firstTime); 
  formData.append('startDate', eventDTO.startDate);
  formData.append('dateEnd', eventDTO.dateEnd);
  formData.append('eventCategoryId', eventDTO.eventCategoryid);
  formData.append('typeId', eventDTO.typeid);
  formData.append('memberMail', eventDTO.memberMail);
  formData.append('firstTime', eventDTO.firstTime);

  // Append the file fields
  formData.append('descriptionImage1', eventDTO.descriptionImage1);
  formData.append('descriptionImage2', eventDTO.descriptionImage2);
  formData.append('mainPicture', eventDTO.mainImage); 

  const response =  await requestFile('POST', serviceApi.SAVE_EVENT,formData,key); 
  return response;
};

export {getEvents,saveEvents};