import { request } from '../globalAxios';

// const { createProxyMiddleware } = require('http-proxy-middleware');

// module.exports = function(app) {
//   app.use(
//     '/api',
//     createProxyMiddleware({
//       target: 'https://nominatim.openstreetmap.org',
//       changeOrigin: true,
//       pathRewrite: {
//         '^/api': '', // remove `/api` from the path
//       },
//     })
//   ); 
// };

const getLocation = async (location) => {
  return await request('get', `/api/search?q=${location}&format=json&limit=1`);
};

export {getLocation};