
const FormPayementMtnMM = ()=>{

    return(
        <>
            <div className='row mt-2'>
                <div className="col-12">
                    <div className="form-group">
                    <label>Phone number *</label>
                    <input type="text" name="name" placeholder="exp: +237 76..." />
                    </div>
                </div>
            </div>
        </>
    );

}

export default FormPayementMtnMM;