import {serviceApi } from '../url';
import {request} from '../globalAxios';
import RequestResponse from '../../domain/model/request_responce';
import EventPricingModel from '../../domain/model/event_pricing_model';

const creatEventPricing = async (element,event,key) => {

  console.info("************* Pricing create ************");
  var data = {
    "cost": Number(element.amount),
    "tax" : 0.0,
    "nbrTicket": Number(element.numberPlace),
    "collation": element.options,
    "priceCategorie" :element.category,
    "free": false,
    "activate" : true,
    "eventId": event.data.id
  };
  console.log("*********** pricing data: ",data);
    
    const response =  await request('post', serviceApi.CREAT_EVENT_PRICING,data,key);

    console.log("---- response pricing ---------");
    console.log(response);
    
    return new RequestResponse(new EventPricingModel(response.data),response.sucess,response.pageSize,response.page,response.exception);
  };
  
  export {creatEventPricing}; 