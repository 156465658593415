import React, { useState } from 'react';
import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import axios from 'axios';
import CreateEventService from '../domain/usecase/create_event_service';

const MapboxAutocomplete = () => {
  const [options, setOptions] = useState([]);

  const { setEventData} = CreateEventService();

  const handleInputChange = async (event) => {
    const query = event.target.value;
    if (query.length > 2) {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json`,
          {
            params: {
              access_token: 'pk.eyJ1IjoiZG9udmV4IiwiYSI6ImNrbnUxOHVrYTA4ZXAycG1rYWt6cmkzcmQifQ.8O98sduAErbrNCQzxZMbfg',
              limit: 15,
              country: "CMR",
            },
          }
        );
        console.log(response);
        const places = response.data.features.map((place) => ({
          label: place.place_name,
          value: place,
          lat: place.center[0],
          log:place.center[1],
          country:place.context[1].text,
        }));
        setOptions(places);
      } catch (error) {
        console.error('Error fetching data from Mapbox API:', error);
      }
    }
  };

  return (
    <Autocomplete
      options={options}
      onChange={(event, value)=>{
        console.log("select ********* "+value.lat);
        setEventData("latitude",value.lat); 
        setEventData("longitude",value.log); 
        setEventData("country",value.country);
        setEventData("area",value.label); 
      }}
      getOptionLabel={(option) =>{
        //setEventData("latitude",option.lat);
        return option.label+" "+option.lat+" "+option.log;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for a place"
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
    />
  );
};

export default MapboxAutocomplete;
