import { Link } from "react-router-dom";
import CreateEventService from "../../domain/usecase/create_event_service";
import PricingComponant from "../pricing_componant";
import ModalCreatePricing from "../modal_pricing/modal_create_pricing";
import PricingStore from "../../domain/usecase/model_pricing_service";
import FormErrorFieldMessage from "../form_error_feild";

const Step5 = ()=>{
    const { switchStep} = CreateEventService();
    const { pricings,errorPrincing,isErrorPricing,resetErrorPricing} = PricingStore();

    return(
        <>
            <div className="checkout-section">
                <div className="container">
                    <div className="checkout-field-wrap">
                        <h3>Create your event ticket <FormErrorFieldMessage message={errorPrincing}  /></h3>
                        <span>Define pricing and advantage for .</span>
                        <div className="row">
                            
                            <div className="col-md-12 mt-4">
                                    <aside className="sidebar">
                                        <div className="widget-bg widget-paymet-bank">
                                            <div className="payment-wrap">
                                                <h4 className="bg-title">Pricing</h4>
                                                <div className="form-group" > 
                                                    <ul>  
                                                        {pricings!==null&&pricings.map((p,keyId)=><PricingComponant place={p.numberPlace} pricingId={p.id} amount={p.amount} desc={p.options} keyPlace={p.category}/>)}
                                                    </ul>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </aside>
                            </div>
                            <ModalCreatePricing action="create"/>
                        </div>
                        <div className="row mt-4">
                            {/*<!-- post navigation html -->*/}
                            <div className="post-navigation" >
                                <div className="nav-prev" onClick={()=>switchStep(4)}>
                                    <a href="#">
                                    <span className="nav-label">Step4</span>
                                    <span className="nav-title">Back to event location</span>
                                    </a>
                                </div>
                                <div className="nav-next" >
                                    {pricings.length>0&&
                                        <Link to="/event_preview">
                                            <span className="nav-label">Preview Event Page</span>
                                            <span className="nav-title">Visualise your event page</span>
                                        </Link>
                                    }
                                    {pricings.length===0&&
                                        <Link to="" onClick={()=>{
                                            resetErrorPricing(true,"Please add some pricing")
                                        }}>
                                            <span className="nav-label">Preview Event Page</span>
                                            <span className="nav-title">Visualise your event page</span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    )
}

export default Step5;