import React from 'react';

const Spinner = ({ src, alt }) => {
  return (
    <>
        <i className='fa fa-spinner rotating-image'></i>
    </>
  );
};

export default Spinner;
