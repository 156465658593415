import { red } from "@mui/material/colors";


const FormErrorFieldMessage = ({message})=>{

    return(
        <>
            <span  style={{color:"red",fontSize:"17px"}}>{message}</span>
        </>
    );

}

export default FormErrorFieldMessage; 