import { Link } from "react-router-dom";

const MainTopEventTicketItem = ({url,img})=>{

    return (
       <>
         <Link to={url} className="grid-item col-md-3">
            
                <figure>
                    <img src={img} alt=""/>
                </figure>
            
        </Link>
       </>
        
    );
}

export default MainTopEventTicketItem;

