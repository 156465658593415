
const FormPayementBankCard = ()=>{

    return (
        <>
            <div className='row mt-2'>
                <div className="col-12">
                    <div className="form-group">
                    <label>Card number *</label>
                    <input type="text" name="name"  />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>Expiration Date *</label>
                    <input type="text" name="name" placeholder="MM / YY *" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>Security code *</label>
                    <input type="text" name="name" placeholder="123" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>Zip code *</label>
                    <input type="text" name="name" placeholder="exemple: 95" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormPayementBankCard;

