import {create} from 'zustand';
import { getEvents } from '../../data/api/event_api';

const eventStore = create((set,get) => ({
  events: [],
  eventFilter:[],
  loading: false,
  error: null,
  page:0,
  pageSize:15,
  fetchEvents: async () => {
    set({ loading: true, error: null });
    try {
      const events = await getEvents(get().page,get().pageSize);
      if(events.data !==null){
        set({ events:events.data, loading: false });    
      }else{
        set({loading: false });
        console.log("************************* error ****************************");
      }
      
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },
  fetchFilterEvent: async (keyFilter)=>{
    get().fetchEvents();
    set({ eventFilter:get().events, loading: false });    
  }
}));

export default eventStore;