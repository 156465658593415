import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"; 
import MapboxAutocomplete from "../location_field";
import CreateEventService from '../../domain/usecase/create_event_service';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import FormErrorFieldMessage from '../form_error_feild';
import MapComponent from '../map_componant';


const Step3 = ()=>{
    const { locatedChoice,switchStep,toogleLocatedChoice ,isOnlineActivate,
        isOffLineActivate,
        onlineActivate,
        offLineActivate,
        eventData, setEventData,setstep3Error,step3Error
    } = CreateEventService();

    return(
        <>
            <div className="checkout-section">
                <div className="container">
                    <div className="row">
                        <div className="checkout-field-wrap">
                            <h3>When and who does your event start ?</h3>
                            <span>Tell us more about your event location and when it start.</span>
                            <div className="row mt-4" >
                                    <div className="col-md-4 mt-2">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem label={"Date start * "}>
                                                <DesktopDatePicker value={dayjs(eventData.startDate)} onChange={(e)=>{
                                                    setEventData("startDate",e);
                                                }} />
                                            </DemoItem>
                                        </LocalizationProvider>   
                                        {step3Error[0]&&<FormHelperText error>This field is required</FormHelperText>}                                 
                                    </div>
                                    <div className="col-md-2 mt-2">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem label="Time start *">
                                                <DesktopTimePicker value={dayjs(eventData.startHour)} onChange={(e)=>{ 
                                                    setEventData("startHour",e);
                                                }} />
                                            </DemoItem>
                                        </LocalizationProvider>    
                                        {step3Error[1]&&<FormHelperText error>This field is required</FormHelperText>}                                 
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}> 
                                            <DemoItem label="Date end *">
                                                <DesktopDatePicker value={dayjs(eventData.dateEnd)} onChange={(e)=>{ 
                                                    setEventData("dateEnd",e);
                                                }} />
                                            </DemoItem>
                                        </LocalizationProvider>  
                                        {step3Error[2]&&<FormHelperText error>This field is required</FormHelperText>}                                   
                                    </div>
                                    
                                    <div className="col-md-2 mt-2">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem label="Time End *">
                                                <DesktopTimePicker value={dayjs(eventData.endHour)} onChange={(e)=>{ 
                                                    setEventData("endHour",e);
                                                }} />
                                            </DemoItem>
                                        </LocalizationProvider>       
                                        {step3Error[3]&&<FormHelperText error>This field is required</FormHelperText>}                              
                                    </div>
                                    
                            </div>
                            <div className='row mt-4'>
                                <h3>Is ths event Periodic?</h3>
                                <div className="col-md-12 m-1" >
                                    <div className="form-check form-switch" >
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  onClick={()=>{
                                            setEventData("recurcingEvent",!eventData.recurcingEvent);
                                            console.log("change")
                                        }}/>
                                        <label className="form-check-label" for="flexSwitchCheckChecked" style={{marginLeft:15}}> {eventData.recurcingEvent?"Event will be schedul every time indicated": "Should we schedule this event after a specific number of day? (0 means schedule the day after the event is close)"}</label>
                                    </div>
                                </div>
                                {
                                    eventData.recurcingEvent&&
                                    <div className="col-md-6 mt-4">
                                        <TextField id="outlined" type="number" label="Shedule period" value={eventData.periodique} 
                                        onChange={(e)=>{
                                            console.log("change "+e.target.value);
                                            setEventData("periodique",e.target.value); 
                                        }}
                                        placeholder="Enter number day (0 mean next day after event close)"  sx={{ minWidth: "100%" }}  
                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-clock"/></InputAdornment>,}} />
                                    </div>
                                }
                            </div>
                            <div className="row mt-4">
                                <h3>Where is it located?* <FormErrorFieldMessage message={step3Error[4]}/> </h3>
                                <div className="col-7 mb-2"> 
                                    <a onClick={()=>toogleLocatedChoice(1)}>
                                    <span className="myBadge rounded-pill " onClick={onlineActivate} style={locatedChoice===1?{backgroundColor:"#D1711F",border:"2px solid #D1711F"}:{backgroundColor:"#FFF",border:"2px solid #D1711F",color:"#D1711F"}}>
                                        <i className="fa fa-map-marker"> Find Location</i>                                        
                                    </span>
                                    <span> </span>
                                    </a>
                                    <a onClick={()=>toogleLocatedChoice(2)}>
                                    <span className="myBadge rounded-pill " onClick={offLineActivate} style={locatedChoice===2?{backgroundColor:"#D1711F",border:"2px solid #D1711F"}:{backgroundColor:"#FFF",border:"2px solid #D1711F",color:"#D1711F"}}>
                                        <i className="fab fa-flickr"> Use googleMap link</i>                                        
                                    </span>
                                    </a>
                                </div>
                                {isOnlineActivate&&
                                    <div className="row mt-2">
                                        <div className="col-12">
                                        <MapboxAutocomplete />
                                        </div>
                                        
                                        <div className="col-md-6 mt-4">
                                            <TextField disabled id="outlined-disabled"  label="Country" value={eventData.country} placeholder="Country"  sx={{ minWidth: "100%" }}  
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                        </div>
                                        <div className="col-md-6 mt-4"> 
                                            <TextField disabled id="outlined-disabled" value={eventData.area} label="Area" placeholder="Area" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <TextField disabled id="outlined-disabled" value={eventData.latitude}  label="Latitude" placeholder="Latitude"  sx={{ minWidth: "100%" }}  
                                            InputProps={{startAdornment: <InputAdornment position="start">Lat</InputAdornment>,}} />
                                        </div>
                                        <div className="col-md-6 mt-4"> 
                                            <TextField disabled id="outlined-disabled" value={eventData.longitude} label="Longitude" placeholder="Longitude" sx={{ minWidth: "100%" }}    
                                            InputProps={{startAdornment: <InputAdornment position="start">Log</InputAdornment>,}} />
                                        </div>
                                        <div className='row mt-4'>
                                            <div className="map-section">
                                            <MapComponent lat={eventData.latitude} long={eventData.longitude} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {isOffLineActivate&&
                                    <dic className="col-12 mt-4">
                                        <div className="col-12 mt-4">
                                            <TextField required id="outlined-required"  label="Enter google map Link" onChange={(event) => {
                                                setEventData("locationUrl",event.target.value);                                            
                                            }} placeholder="enter google Meet link" sx={{ minWidth: "100%" }} 
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-share" style={{color: "#000"}}/></InputAdornment>,}} />
                                            
                                        </div>
                                        <div className='row mt-4'>
                                            <div className="map-section">
                                            <MapComponent lat={eventData.latitude} long={eventData.longitude} />
                                            </div>
                                        </div>
                                        
                                    </dic>
                                }
                            </div>
                            <div className="row mt-4">
                                {/*<!-- post navigation html -->*/}
                                <div className="post-navigation" >
                                    <div className="nav-prev" onClick={()=>switchStep(2)}>
                                        <a href="#">
                                        <span className="nav-label">Step2</span>
                                        <span className="nav-title">Back to event Details</span>
                                        </a>
                                    </div>
                                    <div className="nav-next" onClick={()=>{
                                        var errorDetected = false;
                                        if(eventData.startDate===""){
                                            setstep3Error(1,true)
                                            errorDetected = true;
                                        }else{
                                            setstep3Error(1,"");
                                        } 
                                        if(eventData.startHour ===""){
                                            setstep3Error(2,true);
                                            errorDetected = true;
                                        }else{
                                            setstep3Error(2,"")
                                        }
                                        if(eventData.dateEnd === ""){
                                            setstep3Error(3,true);
                                            errorDetected = true;
                                        }else{
                                            setstep3Error(3,"")
                                        }
                                        if(eventData.endHour === ""){
                                            setstep3Error(4,"Data Require");
                                            errorDetected = true;
                                        }else{
                                            setstep3Error(4,"")
                                        }
                                        if(isOnlineActivate&&eventData.country === ""){
                                            console.log("-- 1");
                                            setstep3Error(5,"Data Require");
                                            errorDetected = true;
                                        }

                                        if(isOffLineActivate&&eventData.country === ""){
                                            console.log("-- 2");
                                            setstep3Error(5,"Data Require");
                                            errorDetected = true;
                                        }
                                        
                                        if(eventData.country !== ""){
                                            setstep3Error(5,"")
                                        }
                                        
                                        if(!errorDetected)
                                            switchStep(4)
                                    }}>
                                        <a href="#">
                                        <span className="nav-label">Next4</span>
                                        <span className="nav-title">Enter event images</span>
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step3;