import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper } from '@mui/material';
import CreateEventService from '../domain/usecase/create_event_service';
import FormErrorFieldMessage from './form_error_feild';

function ImageUpload({title,imageKey,localImageSaveKey})  {
    const { setEventData,eventData,step4Error,storeImage,storeMainImage,storeDescriptionImage1,storeDescriptionImage2} = CreateEventService();


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;

      // Save the base64-encoded image in localStorage
      storeImage(imageKey,base64String);
      setEventData(imageKey,file);
    };

    if (file) {
      reader.readAsDataURL(file); // Convert the image to a base64 string
    }
    
    
    
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
      <Paper
        {...getRootProps()}
        elevation={3}
        sx={{
          p: 2,
          border: '2px dashed #3f51b5',
          backgroundColor: isDragActive ? '#f0f0f0' : '#fff',
          cursor: 'pointer'
        }}
      >
        <input type="file"
         {...getInputProps()} onChange={()=>{console.log("change")}}/>
        <Typography variant="h6">
          {isDragActive ? 'Drop the image here ...' : 'Drag & drop an image for '+title+', or click to select one'}
          {imageKey==="mainImage"&&<FormErrorFieldMessage message={step4Error[0]}  />}
        </Typography>
        {imageKey==="mainImage"&&eventData.mainImage && (
          <Box mt={2}>
            <img
              src={storeMainImage}
              alt="Preview"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </Box>
        )}
        {imageKey==="descriptionImage1"&&eventData.descriptionImage1 && (
          <Box mt={2}>
            <img
              src={storeDescriptionImage1}
              alt="Preview"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </Box>
        )}
        {imageKey==="descriptionImage2"&&eventData.descriptionImage2 && (
          <Box mt={2}>
            <img
              src={storeDescriptionImage2}
              alt="Preview"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default ImageUpload;
