import CreateEventService from "../../domain/usecase/create_event_service";
import ImageUpload from "../upload_file";
import FormErrorFieldMessage from "../form_error_feild";


const Step4 = ()=>{
    const { switchStep,setstep4Error,step4Error,eventData} = CreateEventService();

    return(
        <>
            <div className="checkout-section">
                <div className="container">
                    <div className="row">
                        <div className="checkout-field-wrap">
                            <h3>Upload some image for better communication ?</h3>
                            <span>Please follow the size indicated for a better effect.</span>
                            <div className="col">
                                <ImageUpload title={"Slider"} imageKey="mainImage" localImageSaveKey="currentMainImage" />
                                {/*<DragDropFileUpload />*/}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ImageUpload title={"detail 1"} imageKey="descriptionImage1" localImageSaveKey="currentDescriptionImage1" />
                                </div>
                                <div className="col-md-6">
                                    <ImageUpload title={"detail 2"} imageKey="descriptionImage2" localImageSaveKey="currentDescriptionImage2" />
                                </div>
                            </div>
                            <div className="row mt-4">
                            {/*<!-- post navigation html -->*/}
                            <div className="post-navigation" >
                                    <div className="nav-prev" onClick={()=>switchStep(3)}>
                                        <a href="#">
                                        <span className="nav-label">Step3</span>
                                        <span className="nav-title">Back to event location</span>
                                        </a>
                                    </div>
                                    <div className="nav-next" onClick={()=>{
                                        var errorDetected = false;
                                        if( eventData.mainImage === ""){
                                            setstep4Error(1," This Image is require");
                                            errorDetected = true;
                                        }else{
                                            setstep4Error(1,"")
                                        }
                                        if(!errorDetected)
                                        switchStep(5);
                                        
                                        }}>
                                        <a href="#">
                                        <span className="nav-label">Next5</span>
                                        <span className="nav-title">Create your event ticket </span>
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Step4;