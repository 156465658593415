

const Licence = ()=>{

    return (
        <>
            Licence
        </>
    )
}

export default Licence;