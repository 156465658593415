import { Link } from "react-router-dom";
import MemberManagementService from "../../domain/usecase/member_management_service";
import Loader from "../loader";
import { InputAdornment, TextField } from "@mui/material";


const Login = ()=>{

    const {login,forLoginValidation,isLicenceCheck,toogleLicencecheck,toogleActiveSubscription,loading,email,pwd,errorMemberData,setMemberData,isErrorFound} = MemberManagementService();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default behavior
        window.open("/licence", "_blank"); // Open the link in a new tab
      };

    return(
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-from-wrap">
                        <div className="section-heading">
                        <div className="sub-title">ACTIVATE YOUR ACCOUNT AND INJOY OUR SERVICE</div>
                            <h2 className="section-title">Login</h2>
                            
                        </div>
                        <div className="contact-form">
                            
                            <p>   
                                <TextField id="outlined1" error={errorMemberData.email.activate} helperText={errorMemberData.email.message} onChange={(e)=>{                                            
                                        setMemberData("email",e.target.value); 
                                    }} 
                                    value={email} label="Your Email Address *" placeholder="Enter your E-mail*" sx={{ minWidth: "100%" }}    
                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                </p>
                            
                            <p>   
                                <TextField  type="password" id="outlined2" error={errorMemberData.pwd.activate} helperText={errorMemberData.pwd.message} onChange={(e)=>{                                            
                                        setMemberData("pwd",e.target.value); 
                                    }} 
                                    value={pwd} label="Password *" placeholder="Enter your Password" sx={{ minWidth: "100%" }}    
                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-map-marker"/></InputAdornment>,}} />
                                </p>
 
                            <div className='row'>
                                <div className='col-md-6 d-flex justify-content-start'>
                                <p> 
                                    <label>
                                        <input type="checkbox" name="licence" checked={isLicenceCheck} onChange={toogleLicencecheck} /> 
                                        &nbsp;By subscribing you agry with all our <Link to="/licence" onClick={handleClick} >Conditions and rules</Link>.
                                    </label>
                                </p>
                                </div>
                                <div className='col-md-6 d-flex justify-content-end'>
                                <p> 
                                    <label>
                                    <Link to="/licence" onClick={handleClick} >Forgot password</Link>
                                    </label>
                                </p>
                                </div>
                            </div>
                            
                            {loading?<button className="button-round col-12 mt-2" >Processing ...</button>:<button onClick={()=>{
                            //call subscription service
                            forLoginValidation();
                            if(isLicenceCheck){
                                if(!isErrorFound){
                                    login();
                                }else{
                                    console.log("error data")
                                }
                                
                            }else{
                                console.log("error check")
                            }
                                
                            }}  className="button-round col-12 mt-2">Login</button>}

                            <div className='col d-flex justify-content-center mt-2'>
                            <label>
                                    &nbsp;You don't have an accout?  <Link to="#" onClick={toogleActiveSubscription} >Subscribe</Link>.
                                </label>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                </div>
        </>
    )
}

export default Login;