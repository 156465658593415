class EventPricingModel {
    constructor({
      cost,
      tax ,
      nbrTicket,
      collation,
      priceCategorie ,
      free,
      activate ,
      eventId 
    } = {}) {
      this.cost = cost;
      this.tax = tax;
      this.nbrTicket = nbrTicket;
      this.collation = collation;
      this.priceCategorie = priceCategorie;
      this.free = free;
      this.activate = activate;
      this.eventId = eventId;
    }
  }

  export default EventPricingModel;