import Step1 from "../../components/create_event/step1";
import Step2 from "../../components/create_event/step2";
import Step3 from "../../components/create_event/step3";
import Step4 from "../../components/create_event/step4";
import Step5 from "../../components/create_event/step5";
import CreateEventService from "../../domain/usecase/create_event_service";


const CreateEvent = ()=>{
    const { showStep1, showStep2, showStep3,showStep4, showStep5 } = CreateEventService();
   

    return(
        <>
            {showStep1 && <Step1 />}
            {showStep2 && <Step2 />}
            {showStep3 && <Step3 />}
            {showStep4 && <Step4 />}
            {showStep5 && <Step5 />}
        </>
    );
}

export default CreateEvent; 