import { Link, useParams } from "react-router-dom";
import eventStore from "../domain/usecase/event_service";
import MainEventTicketItem from "../components/main_event_ticket/main_event_ticket_item";
import { useEffect } from "react";
import TagFilter from "../components/tag_filter";


const FilterEvent = ()=>{
    const { fetchFilterEvent, loading, error,eventFilter } = eventStore();

    useEffect(() => {
        fetchFilterEvent();
      }, [fetchFilterEvent]);

    if (loading) return <p>Loading...</p>;
   if (eventFilter!=null&&error) return <p>Error: {error}</p>;


    return(
        <>
            <div className="product-outer-wrap product-wrap">
                <div className="container">
                    <div >
                        {/*bloc filter event*/}
                        <div className="product-notices-wrapper">
                        <form method="get col-md-4">
                           <select name="orderby" className="orderby" aria-label="Shop order">
                              <option value="menu_order"  >My Location</option>
                              <option value="rating">My Town</option>
                              <option value="rating">My Country</option>
                              <option value="date">Auther Country</option>                                 
                           </select>
                           <div className="post-navigation-wrap">                            
                        </div>
                           </form>
                           <TagFilter />
                           
                        </div>
                        <div className="product-item-wrapper">
                            <div className="row">
                              {eventFilter!=null&&eventFilter.map((event)=> <MainEventTicketItem key={event.id} event={event}/>)}
                            </div>
                        </div>
                        {/*pagination html start*/}
                        {/*<!-- pagination html start-->*/}
                        <div className="post-navigation-wrap">
                           <nav>
                             <ul className="pagination">
                               <li>
                                 <Link to="#">
                                   <i className="fas fa-arrow-left"></i>
                                 </Link>
                               </li>
                               <li className="active"><Link to="#">1</Link></li>
                               <li><Link to="#">..</Link></li>
                               <li><Link to="#">5</Link></li>
                               <li>
                                 <Link to="#">
                                   <i className="fas fa-arrow-right"></i>
                                 </Link>
                               </li>
                             </ul>
                           </nav>
                        </div>
                        {/*<!-- pagination html start-->*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterEvent;