import { Link } from "react-router-dom";


const EventCategoryItem = ({title,icon,url})=>{

    return (
        <>
            <Link to={url} className="col-md-3">
                <article className="process-item">
                    <div className="process-head">
                        <i aria-hidden="true" className={"icon "+icon}></i>                            
                    </div>
                    <div className="process-content">
                        <h4>
                            {title}
                        </h4>                                    
                    </div>                               
                </article>
            </Link>
        </>
    );
}

export default EventCategoryItem;