import React, { Fragment, useState } from "react";
import {cardService} from "../service/api/card";
import CardsVM from "../view_model/cards_vm";
import { useTranslation } from "react-i18next";

const ContactUs = ()=>{
    const loader = CardsVM((state)=>state.loader);
    const toogleLoader = CardsVM((state)=>state.toogleLoader);
    const [name,setName] = useState("");
    const [message,setMessage] = useState("");
    const [email,setEmail] = useState("");
    const [error,setError] = useState("");
    const {t} = useTranslation(['contact_us','common']);

    return (
        <Fragment>
           <main id="content" className="site-main">
                {/*<!-- Inner Banner html start-->*/}
                <section className="inner-banner-wrap">
                    <div className="inner-baner-container">
                    <div className="overlay-image"></div>
                    <div className="bottom-shape">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                            <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="inner-banner-content">
                                <h2 className="inner-title">{t('COMMON_CONTACTUS',{ns:"common"})}</h2><br/>                                                                                                 
                            </div>
                             
                        </div>
                    </div>
                    </div>                        
                </section>

                {/*<!-- contact form html start -->*/}
                <div className="contact-page-section">
                <div className="left-background-pattern"></div>
                <div className="contact-form-inner">
                    <div className="container">
                        <div className="contact-from-container">
                            <div className="row">
                            <div className="col-md-7">
                                <div className="contact-from-wrap">
                                    <div className="section-heading">
                                        <div className="sub-title">{t("GET_IN_TOUCH")}</div>
                                        <h2 className="section-title">{t("CONTACT_US_SEARCH")}</h2>
                                        <p>{t("YOU_WHANT_DESIGN")}?</p>
                                        <p>{t("YOU_HAVE_QUESTIONS")}?</p>
                                        <p>{t("PLEASE_SEND_ESSAGE")}</p>
                                    </div>
                                    <div className="contact-form">
                                        <p>
                                            <label>{t("YOUR_NAME")}</label>
                                            <input type="text" name="name" onChange={(e)=>setName(e.target.value)} value={name}/> 
                                        </p>
                                        <p>
                                            <label>{t("YOUR_EMAIL")}*</label>
                                            <input type="email" name="email" onChange={(e)=>setEmail(e.target.value)} value={email}/>
                                        </p>
                                        <p>
                                            <label>{t("YOUR_MESSAGE")}</label>
                                            <textarea rows="8" onChange={(e)=>setMessage(e.target.value)} value={message}></textarea>
                                        </p>
                                        {error==="error"&&<p className="errorMessage">
                                            * {t("ERROR_MAIL")}
                                        </p>}
                                        {error==="success"&&<p className="succesMessage">
                                            * {t("SUCCESS_MAIL")}
                                        </p>}

                                        
                                        {!loader&&<div className="col col-12 col-md-auto smallMarginTop" >
                                            <button onClick={async ()=>{
                                                toogleLoader(true);
                                                var resp = await  cardService.sendMail({"sender":email,"message":message,"senderName":name});
                                                setError(resp);
                                                toogleLoader(false);

                                                if(resp==="success"){
                                                    setEmail("");
                                                    setMessage("");
                                                    setName("");
                                                }
                                                
                                            }} className="button-round" >{t("SUBMIT")}</button>
                                        </div>}
                                        {loader&&<div className="d-flex justify-content-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">{t("LOADING")}</span>
                                        </div>
                                        </div>}   
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="map-section">
                                    <iframe src="https://maps.google.com/maps?q=yaounde longkak&t=&z=10&ie=UTF8&iwloc=&output=embed" height="300" allowFullScreen="" loading="lazy"></iframe>
                                </div>
                                <div className="contact-details-list">
                                    <div className="iconbox-wrap d-flex align-items-center">
                                        <div className="iconbox-icon primary-bg">
                                        <i aria-hidden="true" className="icon icon-map-marker1"></i>
                                        </div>
                                        <div className="iconbox-content">
                                        <h4>{t("LOCAL_ADRESS")}</h4>
                                        <span>Yaounde/Rond Point Longkak, Cameroon</span>
                                        </div>
                                    </div>
                                    <div className="iconbox-wrap d-flex align-items-center">
                                        <div className="iconbox-icon primary-bg">
                                        <i aria-hidden="true" className="icon icon-phone-call2"></i>
                                        </div>
                                        <div className="iconbox-content">
                                            <h4>{t("PHONE_NUMBER")}</h4>
                                            <a href="tel:237696971880">Mob: +237 696871880</a>
                                            <a href="tel:237675888025">Mob: +237 675888025</a>
                                        </div>
                                    </div>
                                    <div className="iconbox-wrap d-flex align-items-center">
                                        <div className="iconbox-icon primary-bg">
                                            <i aria-hidden="true" className="icon icon-envelope11"></i>
                                        </div>
                                        <div className="iconbox-content">
                                            <h4>{t("EMAIL")}</h4>
                                            <a href="mailto:devent237@gmail.com">dservice237@gmail.com</a>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
           </main>
        </Fragment>
    );
}

export default ContactUs;

