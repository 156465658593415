

class EventModel{
    
    constructor({id,title,organizer,latitude,longitude,area,tag,summary,description,offline,startHour,
        endHour,country,quantity,periodique,singleEvent,displayStartTime,displayEndTime,startDate,dateEnd,createdDate,
        mainImage,eventCategoryid,typeid,memberid,descriptionImage1,recurcingEvent,descriptionImage2,firstTime}
    ){
        this.id = id;
        this.title = title;
        this.organizer = organizer;
        this.latitude = latitude;
        this.longitude = longitude;
        this.area = area;
        this.tag  = tag;
        this.summary = summary;
        this.description = description;
        this.offline = offline;
        this.startHour = startHour;
        this.endHour = endHour;
        this.country = country;
        this.quantity = quantity;
        this.periodique = periodique;
        this.singleEvent = singleEvent;
        this.displayStartTime = displayStartTime;
        this.displayEndTime = displayEndTime;
        this.startDate = startDate;
        this.dateEnd = dateEnd;
        this.createdDate = createdDate;
        this.mainImage = mainImage;
        this.eventCategoryid = eventCategoryid;
        this.typeid = typeid;
        this.memberid = memberid;
        this.descriptionImage1 = descriptionImage1;
        this.recurcingEvent = recurcingEvent;
        this.descriptionImage2 = descriptionImage2;
        this.firstTime = firstTime;
    }

    // Method to convert all string properties to strings
  convertAllToString() {
    for (const key in this) {
      if (typeof this[key] !== 'boolean' && typeof this[key] !== 'number') {
        this[key] = String(key+":"+this[key]);
      }
    }
  }
}

export default EventModel;