import toast from "react-hot-toast";
import { saveEvents } from "../../data/api/event_api";
import { creatEventPricing } from "../../data/api/event_pricing_api";
import { signup } from "../../data/api/member";
import { createOrganisation } from "../../data/api/organisation";


const createMemberOrganisationService = async (eventData) => {

  ///Create organisation with member id
  return await createOrganisation(eventData).then(async (org)=>{
    /// Create member account with admin role
    if(org.sucess && org.data.id!==null){
      const member = await signup(eventData,org.data.id);
      return member;
    }
    return null;
  });
};

const createEventService = async (eventData,key,pricings)=>{
  ///create event
  saveEvents(eventData,key).then(async (event)=>{
    console.log("********* event ********* ",event);
    ///create pricing
    if(event.sucess){
      for (const element of pricings) {
        toast.success("Event  create with success");
        var pricing = await creatEventPricing(element,event,key);
        if(pricing.success){
          toast.success("Pricing  create with success");
        }else{
          toast.info("Pricing  create with success");
        }
        
        // Handle response if needed
      }
    }
    
  });
  
}

export {createMemberOrganisationService,createEventService};