import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper } from '@mui/material';
import FormErrorFieldMessage from './form_error_feild';
import MemberManagementService from '../domain/usecase/member_management_service';

function ImageLogo({title,imageKey})  {
    const { setMemberData,errorLogo,memberData} = MemberManagementService();
    const [currentImage,setCurrentImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;

      // Save the base64-encoded image in localStorage
      localStorage.setItem(imageKey,base64String);
      setCurrentImage(base64String)
      console.log('Image saved in localStorage');
    };

    if (file) {
      reader.readAsDataURL(file); // Convert the image to a base64 string
    }
    setMemberData("logo",file);    
    
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
      <Paper
        {...getRootProps()}
        elevation={3}
        sx={{
          p: 2,
          border: '2px dashed #3f51b5',
          backgroundColor: isDragActive ? '#f0f0f0' : '#fff',
          cursor: 'pointer'
        }}
      >
        <input type="file"
        accept="image/*" {...getInputProps()} onChange={()=>{console.log("change")}}/>
        <Typography variant="h6">
          {isDragActive ? 'Drop the image here ...' : 'Drag & drop an image for '+title+', or click to select one'}
          {imageKey===imageKey&&<FormErrorFieldMessage message={errorLogo}  />}
        </Typography>
        {currentImage&&<Box mt={2}>
            <img
              src={currentImage}
              alt="Preview"
              style={{ maxWidth: '100%', maxHeight: '100px' }}
            />
          </Box>}
      </Paper>
    </Box>
  );
}

export default ImageLogo;
