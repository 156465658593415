import React from "react";
import { Route, Routes } from "react-router-dom";
import WelcomePage from "./page/welcome_page";
import WeddingCards from "./page/wedding_card";
import ContactUs from "./page/contact_us";
import AboutUs from "./page/about_us";
import Header from "./components/header";
import Footer from "./components/footer";
import './i18n'
import MainTicketEvent from "./page/main_ticket_event";
import SinglePageLayout from "./page/layout/single_page_layout";
import FilterEvent from "./page/filter_event";
import EventDetails from "./page/event_details";
import ScrollToTop from "./components/scrollToTop";
import CreateEvent from "./page/create_event";
import EventPreview from "./page/event_preview";
import { Toaster } from "react-hot-toast";
import Licence from "./page/licence";

function App() {

  return (
    <>
      {/*<Loader/>*/}
     
      <div id="page" className="full-page">
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/weddind_cards" element={<WeddingCards />} />
          <Route path="/event_tickets" element={<MainTicketEvent />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/event_detail/:id" element={<EventDetails />} />
          <Route path="/event_preview" element={<EventPreview />} />
          <Route path="/licence" element={<Licence />} />
          <Route path="/" element={<SinglePageLayout />} >
            <Route path="/filter_event/:filterKey" element={<FilterEvent />} />
            <Route path="/create_event/step1/:filterKey" element={<CreateEvent />} />
          </Route>
        </Routes>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <Footer />
      </div>
    </>
  );
}

export default App;
