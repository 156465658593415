import {serviceApi } from '../url';
import {request} from '../globalAxios';
import RequestResponse from '../../domain/model/request_responce';
import EventCategoryModel from '../../domain/model/event_category_model';

const getCategory = async (page,pageSize) => {
  console.info("************* API pageSize ************ "+pageSize);
  const response =  await request('get', serviceApi.GET_ALL_CATEGORY,{params:{
    page:page,size:pageSize
  }},);
  console.info("************* API response ************");
  console.info(response.data);
  console.info(response.data.map((cat)=>new EventCategoryModel(cat))); 
  console.info("*************************");
  return new RequestResponse(response.data.map((cat)=>new EventCategoryModel(cat)),response.sucess,response.pageSize,response.page,response.exception);
};

export {getCategory};