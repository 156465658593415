import {InputAdornment, TextField } from "@mui/material";
import CreateEventService from "../../domain/usecase/create_event_service";
import RichTextEditor from "../rich_text_editor";
import FormErrorFieldMessage from "../form_error_feild";




const Step2 = ()=>{
    const { switchStep,setEventData,eventData,setstep2Error,step2Error} = CreateEventService();

    return(
        <>
            <div className="checkout-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="checkout-field-wrap">
                                <h3>Tell us more about your event!</h3>
                                <span>Tell us what kind of events you want to host and we’ll help make it happen.</span>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="row mt-4">
                                            <h4>What’s the name of your event* <FormErrorFieldMessage message={step2Error[0]}  /></h4>
                                            <span>This will be your event’s title. Your title will be used to help create your event’s summary, description, category, and tags – so be specific!</span>
                                        </div>                                        
                                        <TextField id="outlined-uncontrolled" value={eventData.title} inputProps={{ maxLength: 40 }} onChange={(event) => {
                                            setEventData("title",event.target.value);                                            
                                        }}
                                         label="Event title *" placeholder="Enter your event name" sx={{ minWidth: "100%" }}
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,}}
                                        />
                                    </div>
                                    <div className="col-12 mt-4">                                        
                                        <div className="row">
                                            <h4>What’s the summary of your event* <FormErrorFieldMessage message={step2Error[1]}  /></h4>
                                            <span>Give us a short sentance who explane your event!</span>
                                        </div>
                                        <TextField id="outlined-uncontrolled" value={eventData.summary} inputProps={{ maxLength: 115 }} onChange={(event) => {
                                            setEventData("summary",event.target.value);                                            
                                        }}
                                         label="Event summary *" placeholder="Summary of the event (max )" sx={{ minWidth: "100%" }}
                                            InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-bookmark"/></InputAdornment>,}}
                                        />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <RichTextEditor data={eventData.description} editData={setEventData} errorMessage={step2Error[2]}/>
                                    </div>
                                </div>
                               

                                <div className="row mt-4">
                                    {/*<!-- post navigation html -->*/}
                                    <div className="post-navigation" >
                                        <div className="nav-prev" onClick={()=>switchStep(1)}>
                                            <a href="#">
                                            <span className="nav-label">Step1</span>
                                            <span className="nav-title">Back to event introduction</span>
                                            </a>
                                        </div>
                                        <div className="nav-next" onClick={()=>{
                                                var errorDetected = false;
                                                if(eventData.title===""){
                                                    setstep2Error(1,"This field is requires")
                                                    errorDetected = true;
                                                }else{
                                                    setstep2Error(1,"");
                                                } 
                                                if(eventData.summary ===""){
                                                    setstep2Error(2,"This field is require");
                                                    errorDetected = true;
                                                }else{
                                                    setstep2Error(2,"")
                                                }
                                                if(eventData.description === "<p><br></p>"|| eventData.description === ""){
                                                    setstep2Error(3,"This field is require");
                                                    errorDetected = true;
                                                }else{
                                                    setstep2Error(3,"")
                                                }
                                                if(!errorDetected)
                                                    switchStep(3)
                                            }}>
                                            <a href="#">
                                            <span className="nav-label">Step 3</span>
                                            <span className="nav-title">Enter Location details</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step2;