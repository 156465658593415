import { Chip } from "@mui/material";
import PricingStore from "../domain/usecase/model_pricing_service";
import ModalCreatePricing from "./modal_pricing/modal_create_pricing";
import { red } from "@mui/material/colors";

const PricingComponant = ({pricingId,place,amount,desc,keyPlace})=>{

    const {deleteItem  } = PricingStore();

    return(
        <>
            <div className="row" style={{marginBottom:15}}>
                <div class="iconbox-wrap iconbox-border d-flex align-items-center flex-wrap">
                    <div className="row">
                        <div class="iconbox-icon primary-bg">
                            <i aria-hidden="true" class="icon icon-medal1"></i>
                        </div>
                        <div class="iconbox-content">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Ticket: {keyPlace} </h4>
                                    <h4>Amount: {amount} XAF</h4>
                                    <p>{place>0?place+" Places remind":"SOLD OUT"}</p>
                                    <p>{place>0&&desc!==""&&<span className="byline">
                                        {desc}
                                    </span>}</p>
                                </div>
                                <div className="row">
                                <div class="col-sm-4 col-lg-4">
                                <ModalCreatePricing action="update" keyPlace={pricingId}/> 
                                </div>
                                <div className="col-md-4">
                                    <button className="button-round" onClick={()=>deleteItem(pricingId)} style={{color:red}}>
                                        DElete
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                        </div>
            
        </>
    );
}

export default PricingComponant;

