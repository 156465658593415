import { Link } from "react-router-dom";


const TagFilter = ()=>{

    return(
        <>
            <div className="row">                        
                <div className="post-socail-wrap">
                    <div className="social-icon-wrap ">
                    <Link to={`/filter_event/${"all_event"}`} className="outline-round my-outline-round">All Event</Link>
                    <Link to={`/filter_event/${"get_start"}`} className="outline-round my-outline-round">Get Started</Link>
                    <Link to={`/filter_event/${"for_you"}`} className="outline-round my-outline-round">For You</Link>
                    <Link to={`/filter_event/${"today"}`} className="outline-round my-outline-round">Today</Link>
                    <Link to={`/filter_event/${"this_weekend"}`} className="outline-round my-outline-round">This Weekend</Link>
                    <Link to={`/filter_event/${"free"}`} className="outline-round my-outline-round">Free</Link>
                    <Link to={`/filter_event/${"intertenment"}`} className="outline-round my-outline-round">Intertenment</Link>
                    <Link to={`/filter_event/${"free"}`} className="outline-round my-outline-round">Free</Link>
                    <Link to={`/filter_event/${"food_drink"}`} className="outline-round my-outline-round">Food & Drink</Link>
                    <Link to={`/filter_event/${"formation"}`} className="outline-round my-outline-round">Formation</Link>
                    <Link to={`/filter_event/${"karaoke"}`} className="outline-round my-outline-round">Karaoke</Link>
                    <Link to={`/filter_event/${"charity_causes"}`} className="outline-round my-outline-round">Charity & Causes</Link>
                    <Link to={`/filter_event/${"sport"}`} className="outline-round my-outline-round">Sport</Link>
                    <Link to={`/filter_event/${"excursion"}`} className="outline-round my-outline-round">Excursion</Link>
                    <Link to={`/filter_event/${"camping"}`} className="outline-round my-outline-round">Camping</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TagFilter;