class EventCategoryModel{

    constructor({id,name,description}){
        this.id = id;
        this.name = name;
        this.description = description;
    }

}

export default EventCategoryModel;