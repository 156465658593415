// src/store/useDataStore.js
import {create} from 'zustand';
import { login } from '../../data/api/member';
import { createMemberOrganisationService } from '../service/eventService';
import toast from 'react-hot-toast';

const MemberManagementService = create((set, get) => ({
  isSubscription: false,
  isLogin: true,
  show: false,
  email: "",
  pwd: "",
  loading:false,
  isLicenceCheck: true,
  isSubscriptionActivate: false,
  logo:"",
  errorLogo:"",
  isErrorFound: false,
  token: "",
  timer: 0,
  expiresIn:"",
  username: "",
  saveToken:(value)=>{
    set({token:value});
    localStorage.setItem("key",value);
  },
  initAccount:()=>{
    ///get login token if exist
    const key = localStorage.getItem("key");
    const timer = localStorage.getItem("timer");
    const username = localStorage.getItem("username");
    console.log("after -- key: "+key+" - timer: "+timer+" - username:"+username??"null"); 
    if (key!==null&&username!=null){ 
      set({token:key, isconnected:true, timer:timer , username:username});
      console.log("key: "+get().token+" - timer: "+get().timer+" - username:"+get().username??"null"); 
    }else{
      set({show:false, isSubscription: false,
        isLogin: true,isconnected:false});
        console.log("en logout");
    }
  },
  memberData:{
    name:"",
    subemail:"",
    whatsappnumber:"",
    phonenumber: "",
    password: "",
    confpassword: "",
    organisation: {
      orgname: "",
      orgdescription:"",
      facebooklink:"",
      linkedinlink: "",
      websitelink:"",
      logo: ""
    },
    organisationid: null,
    rolename: ""
  },
  errorMemberData:{
    email:{
      activate:false,
      message:""
    },
    pwd:{
      activate:false,
      message:""
    },
    name:{
      activate:false,
      message:""
    },
    subemail:{
      activate:false,
      message:""
    },
    whatsappnumber:{
      activate:false,
      message:""
    },
    phonenumber: {
      activate:false,
      message:""
    },
    password: {
      activate:false,
      message:""
    },
    confpassword: {
      activate:false,
      message:""
    },
    organisation: {
      orgname: {
        activate:false,
        message:""
      },
      orgdescription: {
        activate:false,
        message:""
      },
      facebooklink:{
        activate:false,
        message:""
      },
      linkedinlink: {
        activate:false,
        message:""
      },
      websitelink:{
        activate:false,
        message:""
      },
      logo: {
        activate:false,
        message:""
      }
    },
    organisationid: null,
    rolename: ""
  },
  initErrorMemberData:()=>{
    set({isErrorFound:false});
    set({errorMemberData:{
      email:{
        activate:false,
        message:""
      },
      pwd:{
        activate:false,
        message:""
      },
      name:{
        activate:false,
        message:""
      },
      subemail:{
        activate:false,
        message:""
      },
      whatsappnumber:{
        activate:false,
        message:""
      },
      phonenumber: {
        activate:false,
        message:""
      },
      password: {
        activate:false,
        message:""
      },
      confpassword: {
        activate:false,
        message:""
      },
      organisation: {
        orgname: {
          activate:false,
          message:""
        },
        orgdescription: {
          activate:false,
          message:""
        },
        facebooklink:{
          activate:false,
          message:""
        },
        linkedinlink: {
          activate:false,
          message:""
        },
        websitelink:{
          activate:false,
          message:""
        },
        logo: {
          activate:false,
          message:""
        }
      },
      organisationid: null,
      rolename: ""
    }})
  },
  setLogo:(path)=>{
    set({logo:path})
  },
  toogleActiveSubscription:()=>{
    console.log("sdsd");
    set({isSubscriptionActivate: !get().isSubscriptionActivate})
  },
  toogleLicencecheck: ()=>{
    set({isLicenceCheck: !get().isLicenceCheck})
    console.log(get().isLicenceCheck)
  },
  toogleShow: (value)=>{
    set({show:value, isSubscription: false,
      isLogin: true,});
  },
  //token: 'YOUR_AUTH_TOKEN', // Add your token here or fetch it from a secure store
  login: async () => {
    set({loading:true});
    var response = await login({email:get().email,password:get().pwd}); 
    console.log("--- login success out---");
      
    if(response!==null&&response.sucess){
      console.log(response.data);
      set({token:response.data.token, isconnected:true, timer:response.data.expiresIn});
      localStorage.setItem("key",response.data.token);
      localStorage.setItem("timer",response.data.expiresIn);
      localStorage.setItem("username",response.data.username);
      get().initAccount();
      console.log("--- login success in---");
      console.log(response); 
      set({show:false})
    }else{
      console.log("-- faild login");
      set({token:"", isconnected:false, timer:0});
      set({loading:false}); 
    }
    
    set({loading:false}); 
  },
  logout:()=>{ 
    localStorage.removeItem('key')
    localStorage.removeItem('timer')
    localStorage.removeItem('username')
    get().initAccount();
  },
  signin:async ()=>{
    set({loading: true});
    try{
      console.log(" signin ---") 
    var resp =await createMemberOrganisationService(get().memberData);
    if(resp.sucess){
      toast.success("Congratulation, your account have been creat, please Login");
      set({isSubscriptionActivate: false})
    }
    set({loading: false});
    }catch (e){
      console.log(e);
      set({loading: false});
    }
    
    
  },
  setMemberData:(key,value)=>{
    const newMemeberData = get().memberData;
    switch(key){
      case "email": set({email:value}); console.log("email: "+get().email); break;
      case "pwd": set({pwd:value}); console.log("pwd: "+get().pwd); break;
      case "name": newMemeberData.name = value; console.log("name: "+newMemeberData.name); break;
      case "subemail": newMemeberData.subemail = value; console.log("subemail: "+newMemeberData.subemail); break;
      case "whatsappnumber": newMemeberData.whatsappnumber = value; console.log("whatsappnumber: "+newMemeberData.whatsappnumber); break;
      case "phonenumber": newMemeberData.phonenumber = value; console.log("phonenumber: "+newMemeberData.phonenumber); break;
      case "rolename": newMemeberData.rolename = value; console.log("rolename: "+newMemeberData.rolename); break;
      case "password": newMemeberData.password = value; console.log("password: "+newMemeberData.password); break;
      case "confpassword": newMemeberData.confpassword = value; console.log("conf password: "+newMemeberData.confpassword); break;
      case "orgname": newMemeberData.organisation.orgname = value; console.log("orgname: "+newMemeberData.organisation.orgname); break;
      case "orgdescription": newMemeberData.organisation.orgdescription = value; console.log("orgname: "+newMemeberData.organisation.orgdescription); break;
      case "facebooklink": newMemeberData.organisation.facebooklink = value; console.log("facebooklink: "+newMemeberData.organisation.facebooklink); break;
      case "linkedinlink": newMemeberData.organisation.linkedinlink = value; console.log("linkedinlink: "+newMemeberData.organisation.linkedinlink); break;
      case "websitelink": newMemeberData.organisation.websitelink = value; console.log("websitelink: "+newMemeberData.organisation.websitelink); break;
      case "logo": newMemeberData.organisation.logo = value; console.log("logo: "+newMemeberData.organisation.logo); break;
      default: console.log("************** unkonw key *****************");
    }
    set({memberData: newMemeberData});
  },
  forLoginValidation:()=>{
    const newErrorMemberData = get().errorMemberData;
    const email = get().email;
    const pwd = get().pwd;
    set({isErrorFound:false});
    if(email===""){
      newErrorMemberData.email.activate = true;
      newErrorMemberData.email.message = "Member name require *";
      set({isErrorFound:true});
    }
    if(pwd===""){
      newErrorMemberData.pwd.activate = true;
      newErrorMemberData.pwd.message = "Member e-mail require *";
      set({isErrorFound:true});
    }


  },
  formValidation:()=>{
    const memberData = get().memberData;
    const newErrorMemberData = get().errorMemberData;
    
    if(memberData.name===""){
      newErrorMemberData.name.activate = true;
      newErrorMemberData.name.message = "Member name require *";
      set({isErrorFound:true});
    }
    if(memberData.subemail===""){
      newErrorMemberData.subemail.activate = true;
      newErrorMemberData.subemail.message = "Member e-mail require *";
      set({isErrorFound:true});
    }
    if(memberData.whatsappnumber===""){
      newErrorMemberData.whatsappnumber.activate = true;
      newErrorMemberData.whatsappnumber.message = "Member whatsapp Number require *";
      set({isErrorFound:true});
    }
    if(memberData.password===""){
      newErrorMemberData.password.activate = true;
      newErrorMemberData.password.message = "Member password require *";
      set({isErrorFound:true});
    }
    if(memberData.password!==memberData.confpassword){
      newErrorMemberData.confpassword.activate = true;
      newErrorMemberData.confpassword.message = "Password are not the same *";
      set({isErrorFound:true});
    }
    if(memberData.organisation.orgname===""){
      newErrorMemberData.organisation.orgname.activate = true;
      newErrorMemberData.organisation.orgname.message = "Organisation name require *";
      set({isErrorFound:true});
    }
    if(memberData.organisation.orgname===""){
      newErrorMemberData.organisation.orgdescription.activate = true;
      newErrorMemberData.organisation.orgdescription.message = "Organisation description require *";
      set({isErrorFound:true});
    }
    
    set({errorMemberData: newErrorMemberData})
  }
}));

export default MemberManagementService;
