import {create} from 'zustand';

const ModalPayementService = create((set,get) => ({
    show:false,
    loading:false,
    step1:true,
    step2:false,
    isCheckPayment:false,
    isMtnPayement: false,
    isOrangePayement: false,
    toogleShow:()=>{
        console.log("loader");
        
        set({ show: !get().show,step1:true,step2:false});
    },
    toogleIsCheckPayment:()=>{
        console.log("toogleIsCheckPayment");
        if(!get().isCheckPayment)
        set({ isCheckPayment: true,isMtnPayement:false,isOrangePayement:false});
    },
    toogleIsMtnPayement:()=>{
        console.log("toogleIsMtnPayement");
        if(!get().isMtnPayement)
        set({ isCheckPayment: false,isMtnPayement:true,isOrangePayement:false});
    },
    toogleIsOrangePayement:()=>{
        console.log("toogleIsOrangePayement");
        if(!get().isOrangePayement)
        set({ isCheckPayment: false,isMtnPayement:false,isOrangePayement:true});
    },
    toogleLoading:()=>{
        console.log("loader");
        set({ isCheckPayment: false,isMtnPayement:false,isOrangePayement:false});
        set({ loading: !get().loading});
        const timer = setTimeout(() => {
            set({ step1: false, step2:true,loading:false});
          }, 2000);
          

        return () => clearTimeout(timer);

    },
    initStep1:(value)=>{
        set({ step1: value});
    },
    initStep2:(value)=>{
        set({ step2: value});
    }
  
}));

export default ModalPayementService;